import * as React from "react"
import RailguardCard from "./railguard-card"
import Grid from "@material-ui/core/Grid";
import {railguardTypes} from "../railguardTypes";
import { Link as GatsbyLink } from "gatsby";

const RailguardGallery = ({ guardrailImageNameByType }) => {
  return (
    <div style={{
      flexGrow: 1
    }}>
      <Grid container spacing={3} justify={"center"}>
        {
            Object.keys(railguardTypes).map(railguardType => (
              <Grid key={railguardType} item xs={6} sm={6} md={3} lg={3}>
                <GatsbyLink to={`/katalog/${railguardType}`}>
                  <RailguardCard
                    railguardType={railguardType}
                    image={guardrailImageNameByType[railguardType]}
                  />
                </GatsbyLink>
              </Grid>
            ))
        }
      </Grid>
    </div>
  );
}

export default RailguardGallery;