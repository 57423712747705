import * as React from "react"
import {railguardTypes} from "../railguardTypes";
import Card from "@material-ui/core/Card";
import {CardActionArea} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { GatsbyImage } from "gatsby-plugin-image";

const RailguardCard = ({railguardType, image}) => {
  return (
    <Card elevation={1}
          style={{
            height: "100%",
          }}
    >
      <CardActionArea style={{ height: "100%", padding: "6px" }}>
        <GatsbyImage image={image} />
        <Typography variant="button" display="block" gutterBottom align={"center"}>
          <strong>{railguardTypes[railguardType].name}</strong>
        </Typography>
      </CardActionArea>
    </Card>
  );
}

export default RailguardCard;