import * as React from "react"
import Catalogue from "../components/catalogue/catalogue";
import Layout from "../layout/layout";
import {graphql} from "gatsby";
import SEO from "../components/seo";

const CataloguePage = ({ data }) => {
  const guardrailImages = data.allFile.nodes;

  return (
    <Layout>
      <SEO title={"Katalog"} />
      <title>Katalog odbojnic</title>
      <Catalogue guardrailImages={guardrailImages} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allFile(filter: {relativeDirectory: {eq: "guardrails"}}) {
      nodes {
        base
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  }
`

export default CataloguePage
