import React from "react";
import {railguardTypes} from "../railguardTypes";
import RailguardGallery from "./railguard-gallery";

const Catalogue = ({guardrailImages}) => {
  const guardrailImageNameByType = Object.keys(railguardTypes)
    .map(railguardType => ({
      [railguardType]: guardrailImages
        .find(image => image.base === railguardTypes[railguardType].img)
        .childImageSharp
        .gatsbyImageData
    }))
    .reduce((prev, curr) => Object.assign(prev, { ...curr }), {})

  return (
    <>
      <p>Katalog</p>
      <RailguardGallery
        guardrailImageNameByType={guardrailImageNameByType}
      />
    </>
  )
}

export default Catalogue;