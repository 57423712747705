export const railguardTypes = {
  'barieratechniczna': {
    name: "Bariera techniczna",
    img: 'bariera_techniczna_liniowa_W.png'
  },
  'liniowa': {
    name: "Odbojnica liniowa",
    img: 'liniowa_W.png'
  },
  'narozna': {
    name: "Odbojnica narożna",
    img: 'narozna_W.png'
  },
  'slupowa': {
    name: "Odbojnica słupowa",
    img: 'slupowa_W.png'
  },
  'u2': {
    name: "Odbojnica U2",
    img: 'U2_w.png'
  },
  'u3': {
    name: "Odbojnica U3",
    img: 'U3_w.png'
  },
  'u4': {
    name: "Odbojnica U4",
    img: 'U4_w.png'
  },
  'wozkowa': {
    name: "Odbojnica wózkowa",
    img: 'wozkowa_W.png'
  },
}